/**
 * Wylicza marżę dla pojedynczego rozliczenia
 *
 * @param {object} data - pojedyncze rozliczenie.
 * @returns {number}
 */
const countRealProfit = data => {
  // Konwersja wszystkich wartości na liczby
  const profit = Number(data.profit || 0)
  const remainedRevenue = Number(data.remained_revenue || 0)
  const realizedReserve = Number(data.realized_reserve || 0)
  const incomeReserve = Number(data.income_reserve || 0)
  const realizedRevenue = Number(data.realized_revenue || 0)
  const revenue = Number(data.revenue || 0)

  let value

  // Implementacja formuły Excel:
  // =JEŻELI(ORAZ([@[Pozostały przychód]]<>0;[@Przychód]<>[@[Rozliczony przychód z rezerwy]]);[@Zysk]-[@[Pozostały przychód]]-[@[Rozliczony przychód z rezerwy]];[@Zysk]+[@[Rezerwa na przychody]]+[@[Zrealizowany przychód]]-[@[Rozliczony przychód z rezerwy]])
  if (remainedRevenue !== 0 && revenue !== realizedReserve) {
    // zysk - pozostały przychód - rozliczony przychód z rezerwy
    value = profit - remainedRevenue - realizedReserve
  } else {
    // zysk + rezerwa na przychody + zrealizowany przychód - rozliczony przychód z rezerwy
    value = profit + incomeReserve + realizedRevenue - realizedReserve
  }

  return Number(value.toFixed(2))
}

/**
 * Wylicza obrót dla pojedynczego rozliczenia
 *
 * @param {object} data - pojedyncze rozliczenie.
 * @returns {number}
 */
const countRealTurnover = data => {
  // Konwersja wszystkich wartości na liczby
  const revenue = Number(data.revenue || 0)
  const remainedRevenue = Number(data.remained_revenue || 0)
  const incomeReserve = Number(data.income_reserve || 0)
  const realizedRevenue = Number(data.realized_revenue || 0)
  const realizedReserve = Number(data.realized_reserve || 0)

  let value

  // Implementacja formuły Excel:
  // =JEŻELI(ORAZ([@[Pozostały przychód]]<>0;[@Przychód]<>0);[@Przychód]-[@[Pozostały przychód]];[@Przychód]+[@[Rezerwa na przychody]]+[@[Zrealizowany przychód]]-[@[Rozliczony przychód z rezerwy]])
  if (remainedRevenue !== 0 && revenue !== 0) {
    // przychód - pozostały przychód
    value = revenue - remainedRevenue
  } else {
    // przychód + rezerwa na przychody + zrealizowany przychód - rozliczony przychód z rezerwy
    value = revenue + incomeReserve + realizedRevenue - realizedReserve
  }

  return Number(value.toFixed(2))
}

module.exports = {
  countRealProfit,
  countRealTurnover
}
