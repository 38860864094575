import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { Notification, notificationSystemRef, useNotification as notification } from '../../useNotification'

import { connect } from 'react-redux'

import ReactDOMServer from 'react-dom/server'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : (process.env.REACT_APP_API_SERVER || '')

const wholeSig = {
  padding: '20px',
  backgroundColor: '#FFF'
}

class UserProfile extends Component {
  state = {
    dynamicBanner: false,
    isUploading: false,
    userPhotoUrl: null,
    htmlContent: '',
    shortSignature: false
  }

  componentDidMount () {
    const { user } = this.props
    if (user) {
      const userPhotoUrl = user.ownPhoto ? `https://storage.googleapis.com/cubegroup-pl-files${user.ownPhoto}` : user.PERSONAL_PHOTO || ''
      this.setState({ userPhotoUrl }, () => {
        this.updateHtmlContent()
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // Aktualizuj zawartość HTML po zmianie istotnych elementów
    if (prevState.userPhotoUrl !== this.state.userPhotoUrl ||
        prevState.dynamicBanner !== this.state.dynamicBanner ||
        prevState.shortSignature !== this.state.shortSignature) {
      this.updateHtmlContent()
    }

    // Bezpośrednie wypełnienie textarea kodem HTML
    const { user } = this.props
    if (user && document.getElementById('email-sig-html') && document.getElementById('email-sig-html-text')) {
      const htmlContent = document.getElementById('email-sig-html').innerHTML
      document.getElementById('email-sig-html-text').value = htmlContent
    }
  }

  updateHtmlContent = () => {
    const { user } = this.props
    if (user) {
      const htmlElement = this.generateEmailSignature(user, this.state.shortSignature)
      // Zmieniona metoda konwersji React na string HTML
      const htmlString = ReactDOMServer.renderToString(htmlElement)
      this.setState({ htmlContent: htmlString })
    }
  }

  handleCopyHtmlToClipboard = () => {
    const htmlContent = document.getElementById('email-sig-html')

    if (htmlContent) {
      const range = document.createRange()
      range.selectNode(htmlContent)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)

      try {
        const successful = document.execCommand('copy')
        if (successful) {
          notification('Stopka została skopiowana do schowka!', 'info')
        } else {
          notification('Nie udało się skopiować stopki do schowka.', 'error')
        }
      } catch (err) {
        notification('Wystąpił błąd podczas kopiowania: ' + err, 'error')
      }

      window.getSelection().removeAllRanges()
    }
  }

  uploadPhoto = (file) => {
    this.setState({ isUploading: true })

    /* global FileReader */
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const base64String = reader.result.split(',')[1]

      fetch(`${API_SERVER}/api/user/add-photo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ photo: base64String })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const userPhotoUrl = `https://storage.googleapis.com/cubegroup-pl-files${data.photo}`
            const updatedUser = { ...this.props.user, ownPhoto: data.photo }
            this.setState({ userPhotoUrl }, () => this.updateHtmlContent())
            this.props.updateUser(updatedUser)
            notification('Zdjęcie zostało pomyślnie zaktualizowane!', 'success')
          } else {
            notification(`Błąd podczas aktualizacji zdjęcia: ${data.error}`, 'error')
          }
        })
        .catch((ex) => {
          notification(`${ex instanceof Error && ex.message ? ex.message : 'Coś poszło nie tak. Spróbuj ponownie.'}`, 'error')
        })
        .finally(() => {
          this.setState({ isUploading: false })
          const fileInput = document.querySelector('input[type="file"]')
          if (fileInput) fileInput.value = ''
        })
    }

    reader.onerror = (error) => {
      notification(`Błąd odczytu pliku: ${error}`, 'error')
      this.setState({ isUploading: false })
    }
  }

  handleFileChange = (event) => {
    const file = event.target.files[0]
    if (!file) return

    const maxSize = 3 * 1024 * 1024 // 3MB w bajtach
    if (file.size > maxSize) {
      notification('Plik jest zbyt duży. Maksymalny rozmiar to 3MB.', 'error')
      return
    }

    const validFormats = ['image/jpeg', 'image/jpg', 'image/png']
    if (!validFormats.includes(file.type)) {
      notification('Nieprawidłowy format pliku. Dozwolone formaty: JPG, JPEG, PNG.', 'error')
      return
    }

    this.uploadPhoto(file)
  }

  handleRestoreBtxPhoto = async () => {
    this.setState({ isUploading: true })

    fetch(`${API_SERVER}/api/user/restore-photo`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const updatedUser = { ...this.props.user, ownPhoto: undefined }
          this.setState({ userPhotoUrl: this.props.user.PERSONAL_PHOTO || '' }, () => this.updateHtmlContent())
          this.props.updateUser(updatedUser)
          notification('Zdjęcie Bitrixowe zostało przywrócone!', 'success')
        } else {
          notification(`Błąd podczas przywracania zdjęcia: ${data.error}`, 'error')
        }
      })
      .catch((ex) => {
        notification(`${ex instanceof Error && ex.message ? ex.message : 'Coś poszło nie tak. Spróbuj ponownie.'}`, 'error')
      })
      .finally(() => {
        this.setState({ isUploading: false })
      })
  }

  phone (user) {
    let phone = (user.PERSONAL_MOBILE || '').replace(/[^+0-9]/g, '')
    phone = `${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(6, 3)} ${phone.substr(9, 3)}`
    return (user.PERSONAL_MOBILE ? <p style={{ fontSize: '12px', lineHeight: '19px', margin: 0, fontFamily: 'Arial,Helvetica,Helvetica Neue' }}><a href={`tel:${phone.replace(/ /g, '')}`} style={{ fontSize: '12px', lineHeight: '19px', color: '#68696c', textDecoration: 'none', fontFamily: 'Arial,Helvetica,Helvetica Neue', margin: 0 }}>{phone}</a></p> : null)
  }

  generateEmailSignature (user, isShort = false) {
    const userPhoto = this.state.userPhotoUrl

    // Tekst stopki w zależności od wybranej opcji
    const footerText = isShort
      ? 'Dane osobowe zawarte w korespondencji są przetwarzane przez Cube Group SA zgodnie z <a href="https://cubegroup.pl/polityka-prywatnosci/" target="_blank" rel="noopener noreferrer" style="font-size: 9px; color: #68696c; text-decoration: none;">https://cubegroup.pl/polityka-prywatnosci/</a>.'
      : 'Cube Group SA, ul.&nbsp;Puławska 99A, 02&#8209;595 Warszawa, Sąd Rejonowy w&nbsp;Warszawie, XIII Wydział Gospodarczy KRS&nbsp;0000306712, NIP&nbsp;661&#8209;225&#8209;95&#8209;31, Kapitał zakładowy 775 415,70&nbsp;zł. Dane osobowe zawarte w&nbsp;korespondencji elektronicznej są&nbsp;przetwarzane zgodnie z&nbsp;Polityką prywatności:&nbsp;<a href="https://cubegroup.pl/polityka-prywatnosci/" target="_blank" rel="noopener noreferrer" style="font-size: 9px; color: #68696c; text-decoration: none;">https://cubegroup.pl/polityka-prywatnosci/</a>. Ta wiadomość zawiera informacje, które mogą być w&nbsp;całości lub&nbsp;części poufne i&nbsp;&nbsp;lub&nbsp;objęte ochroną prawną i&nbsp;/&nbsp;lub tajemnicą służbową. Jeżeli niniejsza wiadomość została dostarczona przez pomyłkę, proszę zawiadomić nadawcę, usunąć otrzymaną wiadomość. Każde nieautoryzowane kopiowanie, ujawnianie lub&nbsp;rozpowszechnianie załączonej informacji jest&nbsp;zabronione. This message contains information that may be confidential in whole or in part and/or subject to legal protection and/or professional secrecy. If this message has been delivered in error, please notify the sender, delete the message received. Any unauthorized copying, disclosure or distribution of the enclosed information is prohibited.'
    return (
      <div style={wholeSig} id='email-sig-html'>
        <table width='600' cellSpacing='0' cellPadding='0' border='0' bgcolor='#FFF'>
          <tbody>
            <tr>
              <td width='136' style={{ width: '136px' }}>
                <img src={userPhoto} alt={user.NAME} width='120' height='120' border='0' style={{ display: 'block', border: 'none', width: '120px', height: '120px' }} />
              </td>
              <td width='356' style={{ width: '356px' }}>
                <p style={{ fontSize: '12px', lineHeight: '19px', color: '#03050a', margin: 0, fontFamily: 'Arial,Helvetica,Helvetica Neue' }}>Pozdrawiam</p>
                <p style={{ fontSize: '14px', lineHeight: '19px', fontWeight: 'bold', color: '#03050a', margin: 0, fontFamily: 'Arial,Helvetica,Helvetica Neue' }}>{`${user.NAME} ${user.LAST_NAME || ''}`}</p>
                <p style={{ fontSize: '12px', lineHeight: '19px', color: '#68696c', margin: 0, fontFamily: 'Arial,Helvetica,Helvetica Neue', marginTop: '10px' }}>{user.WORK_POSITION}</p>
                <p style={{ fontSize: '12px', lineHeight: '19px', margin: 0, fontFamily: 'Arial,Helvetica,Helvetica Neue' }}>
                  <a href={`mailto:${user.EMAIL}`} style={{ fontSize: '12px', lineHeight: '19px', color: '#68696c', textDecoration: 'none', fontFamily: 'Arial,Helvetica,Helvetica Neue' }}>{user.EMAIL}</a>
                </p>
                {this.phone(user)}
              </td>
              <td width='108' align='right' style={{ width: '108px', textAlign: 'right' }}>
                <img src='https://storage.googleapis.com/cubegroup-pl-files/f/l.png' alt='' width='108' height='56' border='0' style={{ display: 'block', border: 'none', width: '108px', height: '56px' }} />
              </td>
            </tr>
            <tr>
              <td colSpan='3' style={{ paddingTop: '20px' }}>
                <img src={`https://storage.googleapis.com/cubegroup-pl-files/f/bn.${this.state.dynamicBanner ? 'gif' : 'png'}`} alt='' width='600' height='112' border='0' style={{ display: 'block', border: 'none', width: '600px', height: '112px' }} />
              </td>
            </tr>
            <tr>
              <td colSpan='3' bgcolor='#03050a'>
                <table width='600' cellSpacing='0' cellPadding='0' border='0' style={{ border: 'none' }}>
                  <tbody>
                    <tr>
                      <td style={{ paddingLeft: '40px', paddingTop: '12px', paddingBottom: '12px' }}>
                        <a href='https://cubegroup.pl' target='_blank' rel='noopener noreferrer' style={{ color: '#fff', textDecoration: 'none', fontSize: '14px', lineHeight: '19px', fontFamily: 'Arial,Helvetica,Helvetica Neue' }}>cubegroup.pl</a>
                      </td>
                      <td style={{ paddingRight: '40px', paddingTop: '12px', paddingBottom: '12px' }}>
                        <table align='right' style={{ textAlign: 'right', border: 'none' }}>
                          <tbody>
                            <tr>
                              <td style={{ paddingRight: '24px' }}>
                                <div style={{ height: '1px', width: '100px', backgroundColor: '#35373b', marginLeft: 'auto' }} />
                              </td>
                              <td style={{ paddingRight: '12px' }}>
                                <a href='https://pl.linkedin.com/company/cube-group-ltd' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                  <img src='https://storage.googleapis.com/cubegroup-pl-files/f/in.png' alt='li' width='16' height='17' border='0' style={{ display: 'block', border: 'none', width: '16px', height: '17px' }} />
                                </a>
                              </td>
                              <td style={{ paddingRight: '12px' }}>
                                <a href='https://www.youtube.com/@cubegroup4332' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                  <img src='https://storage.googleapis.com/cubegroup-pl-files/f/yt.png' alt='yt' width='16' height='13' border='0' style={{ display: 'block', border: 'none', width: '16px', height: '13px' }} />
                                </a>
                              </td>
                              <td>
                                <a href='https://www.facebook.com/cubegroup' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                  <img src='https://storage.googleapis.com/cubegroup-pl-files/f/fb.png' alt='fb' width='10' height='17' border='0' style={{ display: 'block', border: 'none', width: '10px', height: '17px' }} />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan='3' style={{ paddingTop: '16px' }}>
                <p
                  style={{ fontSize: '9px', lineHeight: '12px', color: '#68696c', margin: 0, fontFamily: 'Arial,Helvetica,Helvetica Neue' }}
                  dangerouslySetInnerHTML={{ __html: footerText }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  handleCopyHtmlText = () => {
    const textarea = document.getElementById('email-sig-html-text')

    if (textarea) {
      textarea.select()

      try {
        const successful = document.execCommand('copy')
        if (successful) {
          notification('Kod HTML został skopiowany do schowka!', 'info')
        } else {
          notification('Nie udało się skopiować kodu HTML do schowka.', 'error')
        }
      } catch (err) {
        notification('Wystąpił błąd podczas kopiowania: ' + err, 'error')
      }
    }
  }

  render () {
    let { user } = this.props
    user = user || {}
    if (user.googleAuth) {
      return <div className='content'><div className='alert alert-danger'>Funkcjonalność dostępna tylko po zalogowaniu przez Bitrix</div></div>
    }
    return (
      <>
        <Notification ref={notificationSystemRef} />
        <div className='content'>
          <Grid fluid>
            <Row>
              <Col lg={12}>
                <div className='padding'>
                  <p><b>UWAGA:</b> Zalecamy korzystać z przeglądarki chrome, w innych przeglądarkach mogą być problemy przy kopiowaniu.</p>
                  <p><b>KOPIOWANIE:</b> Użyj przycisku "kopiuj HTML" lub zaznacz stopkę i wklej ją do programu pocztowego.</p>
                  <p><b>TWOJE ZDJĘCIE:</b> Zdjęcie w Bitrixie musi mieć kwadratowy format, zalecane wymiary 300x300. Zdjęcie jest Twoją wizytówką – zadbaj o to, aby było możliwie najbardziej profesjonalne i reprezentacyjne – jeśli masz wątpliwości, skonsultuj się z Działem PR & Marketing.</p>
                  <p><b>TWOJE DANE:</b> Jeśli chcesz zaktualizować dane możesz to zrobić na stronie: <a href={`https://cube.bitrix24.pl/company/personal/user/${user.ID}/`} target='_blank' rel='noopener noreferrer'>bitrix</a>.</p>
                  <p><b>BANER W STOPCE:</b> Opcjonalnie nasze stopki zawierają banery, w których promujemy ważne dla nas aktualności i informacje. O tym, czy baner jest obowiązkowy dla Ciebie decyduje Dział PR & Marketing oraz Twój przełożony. Jeśli Twoja stopka ma nie mieć baneru, wystarczy, że przy wklejaniu skopiowanej stopki usuniesz go.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <button
                    className='btn btn-warning'
                    type='button'
                    onClick={this.handleRestoreBtxPhoto}
                    style={{ marginRight: '20px' }}
                    disabled={this.state.isUploading}
                  >
                    Przywróć zdjęcie z Bitrixa
                  </button>
                  <label
                    className='btn btn-danger'
                    style={{ marginRight: '10px', cursor: 'pointer' }}
                  >
                    {this.state.isUploading ? 'Przesyłanie...' : 'Prześlij nowe zdjęcie'}
                    <input
                      type='file'
                      accept='.jpg,.jpeg,.png'
                      onChange={this.handleFileChange}
                      style={{ display: 'none' }}
                      disabled={this.state.isUploading}
                    />
                  </label>
                  <small>Akceptowane formaty: JPG, JPEG, PNG. Maksymalny rozmiar: 3MB.</small>
                </div>
                <div className='padding'>
                  <label
                    htmlFor='dynamic-banner'
                    style={{ marginRight: '10px', marginBottom: 0, fontSize: '16px', fontWeight: 400 }}
                  >
                    <input
                      type='checkbox'
                      checked={this.state.dynamicBanner}
                      onChange={(event) => {
                        const dynamicBanner = event.target.checked
                        this.setState({ dynamicBanner }, () => this.updateHtmlContent())
                      }}
                      id='dynamic-banner'
                      style={{ marginRight: '10px', width: '16px', height: '16px', verticalAlign: 'middle', marginTop: 0 }}
                    />
                    Dynamiczny banner
                  </label>
                  <label htmlFor='short-signature' style={{ marginRight: '10px', marginLeft: '20px', marginBottom: 0, fontSize: '16px', fontWeight: 400 }}>
                    <input
                      type='checkbox'
                      checked={this.state.shortSignature}
                      onChange={(event) => {
                        const shortSignature = event.target.checked
                        this.setState({ shortSignature }, () => this.updateHtmlContent())
                      }}
                      id='short-signature'
                      style={{ marginRight: '10px', width: '16px', height: '16px', verticalAlign: 'middle', marginTop: 0 }}
                    />
                    Skrócona wersja stopki
                  </label>

                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={this.handleCopyHtmlToClipboard}
                    style={{ marginLeft: '20px' }}
                  >
                    Kopiuj HTML stopki
                  </button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} id='here'>
                {this.generateEmailSignature(user, this.state.shortSignature)}
              </Col>
            </Row>

            <Row>
              <Col lg={12} style={{ marginTop: '20px' }}>
                <div className='padding'>
                  <h4>Kod HTML stopki emailowej:</h4>
                  <button
                    className='btn btn-success'
                    type='button'
                    onClick={this.handleCopyHtmlText}
                    style={{ marginBottom: '10px' }}
                  >
                    Kopiuj kod HTML
                  </button>
                  <textarea
                    id='email-sig-html-text'
                    value={this.state.htmlContent}
                    readOnly
                    style={{
                      width: '100%',
                      height: '200px',
                      fontFamily: 'monospace',
                      fontSize: '12px',
                      padding: '10px'
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = {
  updateUser: user => ({
    type: 'SET_USER',
    user
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
