import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { toFixed } from '../Components/currencyFormatter'
import { countRealProfit, countRealTurnover } from '../Settlements/calculations'
import './AgGrid.css'
import { CheckboxRenderer, getDatePicker, NumberRenderer } from './AgGridRenderers'

class AgGrid extends Component {
  constructor (props) {
    super(props)
    this.saveFilterModel = this.saveFilterModel.bind(this)
    this.restoreFilterModel = this.restoreFilterModel.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.setFilters = props.setFilters
    this.state = {
      columnDefs: props.columns,
      rowData: props.rows,
      displayedRowCount: '',
      sumDisplayedRows: 0,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          clearButton: true,
          // applyButton: true,
          debounceMs: 250
        },
        width: 110,
        editable: false,
        singleClickEdit: true

      },
      localeText: {
        contains: 'zawiera',
        notContains: 'nie zawiera',
        equals: 'równy',
        notEqual: 'nie równy',
        startsWith: 'zaczyna od',
        endsWith: 'kończy na',
        andCondition: 'ORAZ',
        orCondition: 'LUB',
        clearFilter: 'Resetuj filtry',
        empty: 'Wszystkie',
        lessThan: 'mniejszy niż',
        lessThanOrEqual: 'mniejszy niż lub równy',
        greaterThan: 'większy niż',
        greaterThanOrEqual: 'większy niż lub równy',
        inRange: 'w zakresie'
      },
      pinnedBottomRowData: [{ pinnedBottom: true }],
      agFilters: {}
    }
  }

  componentDidMount () {
    this.restoreFilterModel()
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)) {
      this.setState({
        columnDefs: this.props.columns
      })
    }
    if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
      this.setState({
        rowData: this.props.rows,
        pinnedBottomRowData: [{ pinnedBottom: true }]
      }, () => this.restoreFilterModel())
    }
    if (JSON.stringify(prevProps.searchInput) !== JSON.stringify(this.props.searchInput)) {
      if (this.props.searchInput.length > 1) {
        this.onFilterTextBoxChanged(this.props.searchInput)
      }
      this.gridApi.deselectAll()
    }
    if (prevProps.clearAllFilters !== this.props.clearAllFilters) {
      this.clearFilters()
    }
  }

  /* select row */

  onSelectionChanged () {
    const selectedNodes = this.gridApi.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    if (typeof this.props.setCheckboxesList === 'function') {
      this.props.setCheckboxesList(selectedData)
    }
  }

  /* pinned bottom bow */

  sumDisplayedRows (params, col) {
    this.gridApi = params.api
    let sum = 0
    this.gridApi.forEachNodeAfterFilter(row => {
      const price = parseFloat(Number(row.data[col]) || 0)
      if (price !== '') { sum += price }
    })
    return sum || '0.00'
  }

  getPinnedBottomData (params) {
    const { amountCols } = this.props
    const { pinnedBottomRowData } = this.state

    const bottomRow = {}
    amountCols.forEach(col => {
      bottomRow[col.field] = this.sumDisplayedRows(params, col.field)
    })
    const rowCount = this.gridApi.getDisplayedRowCount()
    bottomRow.order_id = `${rowCount} wierszy`
    bottomRow.pinnedBottom = true

    if (JSON.stringify(pinnedBottomRowData) !== JSON.stringify([bottomRow])) {
      this.setState({ pinnedBottomRowData: [bottomRow] })
    }
  }

  /* filters */

  clearFilters () {
    this.gridApi.setFilterModel(null)
    this.gridApi.onFilterChanged()
  }

  saveFilterModel (params) {
    const { agFilters } = this.state
    const newFilters = params.api.getFilterModel()
    if (JSON.stringify(agFilters) !== JSON.stringify(newFilters)) {
      this.setState({ agFilters: newFilters })
      this.setFilters({
        filters: {
          params: newFilters,
          table: this.props.table,
          timeRange: this.props.filters.timeRange,
          estimationsMonth: this.props.filters.estimationsMonth
        }
      })
      this.gridApi.deselectAll()
    }
  }

  restoreFilterModel () {
    const { agFilters } = this.state
    const { filters, table } = this.props
    const paramsKeys = Object.keys(filters.params || {})
    if (filters.table === table && paramsKeys.length &&
      (JSON.stringify(agFilters) !== JSON.stringify(filters.params))) {
      this.setState({ agFilters: filters.params }, () => {
        setTimeout(() => {
          this.setFilterModel()
        }, 0)
      })
    } else {
      setTimeout(() => {
        this.setFilterModel()
      }, 0)
    }
  }

  onFilterTextBoxChanged (searchInput) {
    this.gridApi.setQuickFilter(searchInput)
    this.gridApi.onFilterChanged()
  }

  setFilterModel () {
    const { agFilters } = this.state
    this.gridApi.setFilterModel(agFilters)
    this.gridApi.onFilterChanged()
  }

  /* after state update */

  handleModelUpdated = params => {
    this.getPinnedBottomData(params)

    setTimeout(() => {
      this.saveFilterModel(params)
    }, 50)
  }

  handleCellValueChanged = (params) => {
    if (this.props.cellValueChanged) {
      this.props.cellValueChanged(params)
    }
  }

  countTotals (params) {
    const { data, column } = params
    // for estimations
    if (this.props.table === 'estimations') {
      data.change = Number((data.estimation || 0).toString().replace(',', '.')) - Number((data.profit1 || 0).toString().replace(',', '.'))
      data.accepted = column.colId !== 'comment' ? null : data.accepted
    }

    // for settlements
    if (this.props.table === 'settlements') {
      data.revenue = toFixed(data.revenue)
      data.cost = toFixed(data.cost)
      data.VR_cost = toFixed(data.VR_cost)
      data.total_costs = [data.cost || 0, data.VR_cost || 0]
        .map(Number).reduce((a, b) => a + b).toFixed(2)
      data.profit = [data.revenue || 0, data.total_costs || 0]
        .map(Number).reduce((a, b) => a - b).toFixed(2)
      data.real_profit = countRealProfit(data).toFixed(2)
      data.real_turnover = countRealTurnover(data).toFixed(2)
    }
    this.gridApi.refreshCells(params)
  }

  onCellEditingStopped (params) {
    this.countTotals(params)
    this.getPinnedBottomData(params)
    this.onSelectionChanged()
  }

  render () {
    const { columnDefs = [], defaultColDef = {}, rowData = [], localeText, pinnedBottomRowData = [] } = this.state
    return (
      <div
        className='ag-theme-balham'
        style={{
          height: window.innerHeight * 0.65,
          width: '100%'
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          rowSelection='multiple'
          onGridReady={params => {
            this.gridApi = params.api
            return params.api
          }}
          animateRows
          pinnedBottomRowData={typeof this.props.pinnedBottomRowData !== 'undefined' ? this.props.pinnedBottomRowData : pinnedBottomRowData}
          stopEditingWhenGridLosesFocus
          onModelUpdated={this.handleModelUpdated}
          onCellEditingStopped={this.onCellEditingStopped.bind(this)}
          localeText={localeText}
          onSelectionChanged={this.onSelectionChanged.bind(this)}
          suppressRowClickSelection
          suppressMenuHide
          headerCheckboxSelectionFilteredOnly
          onCellValueChanged={this.handleCellValueChanged}
          frameworkComponents={{ checkboxRenderer: CheckboxRenderer, numberRenderer: NumberRenderer }}
          components={{ datePicker: getDatePicker() }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.settlements.filters
  }
}

const mapDispatchToProps = {
  setFilters: data => ({
    type: 'SETTLEMENTS_FILTERS',
    filters: data
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(AgGrid)
