import React from 'react'
import { style as notificationsStyle } from './variables/Variables'
import NotificationSystem from 'react-notification-system'

export const Notification = React.forwardRef((props, ref) => {
  return <NotificationSystem ref={ref} style={notificationsStyle} />
})

export const notificationSystemRef = React.createRef()

export const useNotification = (message, level = 'error', autoDismiss = 6, position = 'br') => {
  const notificationSystem = notificationSystemRef.current
  const icons = {
    error: 'pe-7s-bandaid',
    success: 'pe-7s-check',
    info: 'pe-7s-cloud-upload'
  }
  if (notificationSystem) {
    notificationSystem.addNotification({
      title: <span data-notify='icon' className={icons[level]} />,
      message,
      level,
      autoDismiss,
      position
    })
  }
}
