import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, FormControl, Grid, Row } from 'react-bootstrap'
import moment from 'moment'
import NotificationSystem from 'react-notification-system'
import { style } from '../../../variables/Variables'
import { Card } from '../../../components/Card/Card'
import Select from 'react-select'
import Loader from '../Components/Loader'
import { SingleDatePicker } from 'react-dates'
import { formSettlement, settlementSource } from './settlements'
import { allowedFromDepartments, allowedRespDepartments, API_SERVER, checkUserType } from '../authData'
import { getMainMap, getMapLabel, onlyActiveUsers } from '../common'
import { Link } from 'react-router-dom'
import { checkConditions, checkValidation } from './validate'
import NumberFormat from 'react-number-format'
import { getPrograms } from './programs'
import { countRealProfit, countRealTurnover } from './calculations'
import QuestionMarkIco from '../../../assets/img/question-mark.svg'

const FORM_MODEL = formSettlement
const READ_ONLY_INPUTS = [
  'order_id',
  'job_id',
  'main_depart',
  'main_person',
  'from_depart',
  'from_person',
  'resp_depart',
  'company',
  'client',
  'order_name',
  'total_costs',
  'profit',
  'real_profit',
  'real_turnover'
]

const SHOW_INPUTS = Object.entries(FORM_MODEL).reduce((a, el, key) => { a[el[0]] = key < 16; return (a) }, {})

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 1 }),
  control: style => ({ ...style, minHeight: 22, borderStyle: 'none', borderBottom: '1px solid #E3E3E3' }),
  valueContainer: style => ({ ...style, padding: '0 8px' }),
  clearIndicator: style => ({ ...style, padding: '0 4px', width: 20 }),
  indicatorSeparator: style => ({ style, display: 'none' })
}

class SettlementDetails extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      loader: true,
      today: moment().format('YYYY-MM-DD'),
      colParams: FORM_MODEL,
      requiredForSaveButton: Object.keys(FORM_MODEL).filter(prop => FORM_MODEL[prop].req1),
      requiredForSettleButton: Object.keys(FORM_MODEL).filter(prop => FORM_MODEL[prop].req1 || FORM_MODEL[prop].req2),
      settlements: [],
      formValid: true,
      readOnlyInputs: READ_ONLY_INPUTS,
      correction: false,
      correctionValues: {},
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main),
      settlementSource,
      showInputs: SHOW_INPUTS
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    const isOffice = checkUserType(loggedUser, 'office')

    let { slug } = this.props.match.params
    if (slug.includes('correction')) {
      slug = slug.split('-')[1]
      this.setState({ correction: true })
    } else {
      slug = slug.split('?')[0]
    }
    const jobId = this.props.match.params.slug.split('?')[1] || this.props.location.search.substr(1)

    getPrograms()
      .catch(() => this.showNotification('error', 'Wystąpił błąd'))
      .then(programs => {
        if (programs) {
          this.setState({
            programs,
            slug,
            jobId,
            isFinance,
            isOffice
          }, () => this.loadData())
        }
      })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  loadData () {
    let { slug, jobId, correction, readOnlyInputs, colParams, loggedUser, isOffice, isFinance } = this.state
    fetch(`${API_SERVER}/api/get-settlements`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        _id: slug,
        jobId
      })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        if (!response || response.error) {
          this.showNotification('error', `Wystąpił błąd: ${(response && response.error) || ''}`)
          console.error('Error:', (response && response.error) || '')
        }
        if (response) {
          if (slug === 'add' && jobId === '' && (isOffice || isFinance || this.state.loggedUser.ID === '22')) {
            response = [{
              main_depart: '48',
              from_depart: '48',
              resp_depart: '48',
              main_person: loggedUser.ID || '',
              from_person: loggedUser.ID || '',
              resp_person: loggedUser.ID || ''
            }]
          }

          if (correction) {
            this.setState({
              correctionValues: {
                prevRevenue: response[0].revenue,
                prevCost: response[0].cost
              }
            });
            ['settled', 'settleDate', 'revenue', 'cost', 'supplier', 'income_reserve', 'realized_reserve',
              'realized_revenue', 'remained_revenue', 'prepayment_cost', 'prepayment_year',
              'VR_cost', 'total_costs', 'profit', 'real_profit', 'real_turnover']
              .forEach((el) => {
                delete response[0][el]
              })
            readOnlyInputs = Object.keys(colParams)
              .filter(el => !['settled', 'revenue', 'cost', 'supplier', 'comments'].includes(el))
          }
          this.setState({ settlements: response, loader: false },
            () => this.dataConditions()
          )

          // Edycja dla działu Office

          if (slug === 'add' && jobId === '' && (isOffice || isFinance || this.state.loggedUser.ID === '22')) {
            readOnlyInputs = readOnlyInputs.filter(el => !['company', 'order_name'].includes(el))
          } else {
            readOnlyInputs = [...readOnlyInputs, 'company', 'order_name']
          }
          if (slug !== 'add' && jobId === '' && (isOffice || isFinance || this.state.loggedUser.ID === '22')) {
            readOnlyInputs = [...readOnlyInputs, 'month']
          }
          this.setState({ readOnlyInputs })
        } else {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', response)
        }
      })
  }

  allowedDepartments (allDepart, allowed) {
    return allDepart.filter(el => allowed.includes(el.ID))
  }

  allowedUsers (allUsers, allowed) {
    const { isFinance } = this.state
    const filteredUsers = allUsers.filter(el => (el.scDepartment || []).includes(allowed))
    return !isFinance ? onlyActiveUsers(filteredUsers) : filteredUsers
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'tr',
      autoDismiss
    })
  }

  format2d (value) {
    if (value) {
      const dotValue = parseFloat(value.replace(/ /g, '').replace(',', '.'))
      if (isNaN(dotValue)) {
        return this.showNotification('error', 'Niepoprawna wartość liczbowa')
      }
      return (Math.round(dotValue * 100) / 100).toFixed(2)
    }
  };

  toNumber (num) {
    return num.toString().replace('.', ',')
  }

  getRangeOfDates (start, end, key, arr = [start.startOf(key)]) {
    if (start.isAfter(end)) return this.showNotification('error', 'Data startu poźniejsza od daty zakończenia', 0)
    // throw new Error('start must precede end');
    const next = moment(start).add(1, key).startOf(key)
    if (next.isAfter(end, key)) return arr
    return this.getRangeOfDates(next, end, key, arr.concat(next))
  }

  getLabel (arr = [], val, row) {
    if (['month', 'category', 'model', 'system', 'space', 'prepayment_year', 'settled'].includes(row)) { return val }
    const obj = arr.length ? arr.find(o => o.ID === val) : ''
    return obj ? obj.NAME || obj.TITLE : ''
  }

  dataConditions () {
    const { settlements = [], colParams, showInputs, requiredForSettleButton } = this.state
    const conditions = checkConditions(settlements[0], colParams, showInputs, requiredForSettleButton)
    this.setState({ ...conditions })
  }

  dataValidation () {
    const { settlements = [], requiredForSaveButton, requiredForSettleButton } = this.state
    const formValid = checkValidation(settlements[0], requiredForSaveButton, requiredForSettleButton)
    this.setState({ formValid })
  }

  checkInvalidInput (val, row) {
    const { settlements = [], requiredForSaveButton, requiredForSettleButton } = this.state
    if (settlements.length && settlements[0].settled !== 'tak') {
      return (!this.state.formValid && requiredForSaveButton.includes(row) && (!val)) ? 'error' : ''
    } else {
      return (!this.state.formValid && requiredForSettleButton.includes(row) && (!val)) ? 'error' : ''
    }
  };

  getRowValues (row, slug, colParams, data = {}) {
    const { settlements = [], formValid, jobId, isOffice, isFinance, correction, mainMap } = this.state
    const { readOnlyInputs } = this.state
    let options; let initValue = {}

    // Obsługa selectów
    if (colParams[row].type === 'select') {
      let source = this.props.main[colParams[row].source] || []
      if (row === 'company') { source = source.filter(el => el.UF_CRM_1542970468 === undefined || el.UF_CRM_1542970468 === '1') };

      // Filtrowanie dozwolonych działów

      if (row === 'main_depart') {
        source = this.allowedDepartments(source, allowedFromDepartments) || []
      }

      if (row === 'from_depart') {
        source = this.allowedDepartments(source, allowedFromDepartments) || []
      }

      if (row === 'resp_depart') {
        source = this.allowedDepartments(source, Object.keys(allowedRespDepartments).map(Number)) || []
      }

      // Odfiltrowanie modelu 'FF' jeśli kategoria 'Afiliacja', 'Afiliacja+'

      if (row === 'category') {
        if (['Afiliacja', 'Afiliacja+'].includes(data.category)) {
          settlementSource.model = settlementSource.model.filter(el => !['FF'].includes(el))
        } else {
          if (settlementSource.model.indexOf('FF') === -1) {
            settlementSource.model.push('FF')
          }
        }
      }

      // Filtrowanie powierzchni w zależności od działu realizującego i kategorii
      if (row === 'space') {
        // Sprawdzenie specjalnych kombinacji działu i kategorii
        const specialSpaceKey = `${data.resp_depart}-${data.category}`
        if (data.resp_depart && data.category && settlementSource.specialSpaces && settlementSource.specialSpaces[specialSpaceKey]) {
          source = settlementSource.specialSpaces[specialSpaceKey] || []
        } else if (data.resp_depart) {
          source = settlementSource[colParams[row].source2][data.resp_depart] || []
        } else {
          source = []
        }
      } else {
        if (colParams[row].source2) {
          source = settlementSource[colParams[row].source2] || []
        }
      }

      // Filtrowanie kategorii w zależności od działu realizującego

      if (row === 'category') {
        source = allowedRespDepartments[parseInt(data.resp_depart, 10)] || []
      }

      // Filtrowanie użytkowników w zależności od działu

      const { mainDepart, fromDepart, respDepart } = this.state.settlements[0] || []
      if (row === 'main_person') { source = this.allowedUsers(source, mainDepart) || [] }
      if (row === 'from_person') { source = this.allowedUsers(source, fromDepart) || [] }
      if (row === 'resp_person') { source = this.allowedUsers(source, respDepart) || [] }

      // Wybór dostępnych miesięcy ze względu na daty startu i końca zadania

      if (row === 'month' && settlements.length) {
        const rangeOfDates = this.getRangeOfDates(moment(settlements[0].jobStart), moment(settlements[0].jobEnd || moment().add(1, 'M').format('YYYY-MM-DD')), 'month')
        source = rangeOfDates.map(el => moment(el).format('YYYY-MM')).reverse() || []
      }

      // Wybór dla selecta 'prepayment_year'

      if (row === 'prepayment_year') {
        const rangeOfDates = this.getRangeOfDates(moment('2018-01-01'), moment(), 'year')
        source = rangeOfDates.map(el => moment(el).format('YYYY')).reverse() || []
      }

      // Dane dla selecta 'programId'

      if (row === 'programId' && data.system) {
        source = this.state.programs[data.system] || []
      }

      // Dane dla działu Office

      if (isOffice || isFinance || this.state.loggedUser.ID === '22') {
        if (slug === 'add' && jobId === '') {
          const data = settlements[0] || {}
          if (row === 'company') {
            data.client = data.company ? getMapLabel(mainMap.companies, data.company) : ''
          }

          if (row === 'month' && settlements.length) {
            const start = (moment().format('YYYY') > '2019')
              ? moment().subtract(13, 'months')
              : '2019-01-01'

            const rangeOfDates = this.getRangeOfDates(moment(start), moment(settlements[0].jobEnd || moment().add(1, 'M').format('YYYY-MM-DD')), 'month')
            source = rangeOfDates.map(el => moment(el).format('YYYY-MM')).reverse() || []
          }
        }
      }

      // Przygotowanie wartości w selectach

      options = source.map(item => {
        return ({
          value: item.ID || item,
          label: item.NAME || item.TITLE || item
        })
      })

      // Ustawienie wartości początkowych w selectach

      const dataArr = (row === 'programId' && data.system)
        ? this.state.programs[data.system]
        : this.props.main[colParams[row].source]

      initValue = {
        value: data[row] ? data[row] : '',
        label: this.getLabel(dataArr, data[row], row)
      }
    }

    // Obliczenia

    if (row === 'total_costs') {
      data.total_costs = [data.cost || 0, data.VR_cost || 0]
        .map(Number).reduce((a, b) => a + b).toFixed(2)
    }

    if (row === 'profit') {
      data.profit = [data.revenue || 0, data.total_costs || 0]
        .map(Number).reduce((a, b) => a - b).toFixed(2)
    }

    if (row === 'real_profit') {
      data.real_profit = countRealProfit(data).toFixed(2)
    }

    if (row === 'real_turnover') {
      data.real_turnover = countRealTurnover(data).toFixed(2)
    }

    // Renderowanie

    if (colParams[row].type === 'select') {
      return (
        <Select
          options={options}
          styles={selectStyles}
          name={row}
          value={initValue}
          isClearable
          isDisabled={!isFinance && (readOnlyInputs.includes(row) || data.settleDate)}
          className={this.checkInvalidInput(data[row] || '', row)}
          onChange={(e) => {
            data[row] = e ? e.value : ''
            if (row === 'system') { delete data.programId }
            if (row === 'main_depart') { delete data.main_person }
            if (row === 'from_depart') { delete data.from_person }
            if (row === 'resp_depart') { delete data.resp_person }

            this.setState(() => ({ settlements: [data] }),
              // () => { checkConditions((data, colParams, showInputs, requiredForSettleButton) => this.setState({aa: 2}))});
              () => this.dataConditions())
            if (!formValid) this.dataValidation()
          }}
        />
      )
    } else if (colParams[row].type === 'date') {
      return (
        <div>
          <SingleDatePicker
            date={data[row] ? moment(data[row]) : null}
            onDateChange={date => {
              data[row] = date ? moment(date).format('YYYY-MM-DD') : ''
              this.setState({ settlements: [data] })
              if (!formValid) this.dataValidation()
            }}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            id={row}
            placeholder={null}
            displayFormat='YYYY-MM-DD'
            disabled={!isFinance && (!!data.settleDate || correction)}
            showClearDate
            small
            showDefaultInputIcon
            isOutsideRange={date => date < moment('2019-01-01')}
            numberOfMonths={1}
          />
          <div
            style={{ width: 178 }}
            className={this.checkInvalidInput(data[row] || '', row)}
          />
        </div>
      )
    } else if (colParams[row].type === 'amount') {
      return (
        <NumberFormat
          name={row}
          value={data[row]}
          customInput={FormControl}
          thousandSeparator={' '}
          decimalSeparator=','
          allowedDecimalSeparators={[',', '.']}
          fixedDecimalScale
          decimalScale={2}
          isNumericString
          placeholder={
            correction && (row === 'revenue' || row === 'cost')
              ? `Wpisz różnicę z korekty (pierwotna wartość ${row === 'cost' ? this.state.correctionValues.prevCost : this.state.correctionValues.prevRevenue})`
              : ''
          }
          className={this.checkInvalidInput(data[row] || '', row)}
          readOnly={!isFinance && (readOnlyInputs.includes(row) || data.settleDate) ? 'readonly' : ''}
          onBlur={(e) => {
            data[row] = this.format2d(e.target.value)
            this.setState(() => ({ settlements: [data] }),
              () =>
                !formValid ? this.dataValidation() : '',
              this.dataConditions()
            )
          }}
          onValueChange={(values) => {
            data[row] = values.value
            this.setState(() => ({ settlements: [data] }),
              () =>
                !formValid ? this.dataValidation() : '',
              this.dataConditions()
            )
          }}
        />
      )
    } else {
      return (
        <FormControl
          type='text'
          name={row}
          value={data[row] ? data[row] : ''}
          className={this.checkInvalidInput(data[row] || '', row)}
          readOnly={!isFinance && (readOnlyInputs.includes(row) || data.settleDate) ? 'readonly' : ''}
          onChange={(e) => {
            data[row] = e.target.value
            this.setState(() => ({ settlements: [data] }),
              () =>
                !formValid ? this.dataValidation() : '',
              this.dataConditions()
            )
          }}
        />
      )
    }
  }

  newrenderSettlementsDetailsTable () {
    const { colParams, slug = '', settlements = [], showInputs, mainMap = {} } = this.state
    const { logs = {} } = settlements[0] || {}

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }} className='blue-tooltip'>
        <Col md={12} lg={6}>
          <Card
            title='Ogólne'
            content={
              Object.entries(colParams).map((row, key2) => {
                const tooltip = row[1].tooltip
                  ? `<span class="info-tooltip">
                    <img src='${QuestionMarkIco}' alt='' style="width:12px;height:12px" />
                    <div style="left:30px;right:auto;width:270px;">
                      <p style="font-size:11px;min-width:auto;">${row[1].tooltip}</p>
                    </div>
                  </span>`
                  : ''
                return (
                  row[1].card === 1
                    ? Object.keys(showInputs).filter(el => showInputs[el] === true).includes(row[0])
                      ? <div key={key2}>
                        <label dangerouslySetInnerHTML={{ __html: row[1].pl + tooltip }} />
                        {this.getRowValues(row[0], slug, colParams, settlements[0])}
                      </div>
                      : null
                    : null
                )
              })
            }
          />
          <Card
            title='Szczegóły'
            content={
              Object.entries(colParams).map((row, key2) => {
                const tooltip = row[1].tooltip
                  ? `<span class="info-tooltip">
                    <img src='${QuestionMarkIco}' alt='' style="width:12px;height:12px" />
                    <div style="left:30px;right:auto;width:270px;">
                      <p style="font-size:11px;min-width:auto;">${row[1].tooltip}</p>
                    </div>
                  </span>`
                  : ''
                return (
                  row[1].card === 2
                    ? Object.keys(showInputs).filter(el => showInputs[el] === true).includes(row[0])
                      ? <div key={key2}>
                        <label dangerouslySetInnerHTML={{ __html: row[1].pl + tooltip }} />
                        {this.getRowValues(row[0], slug, colParams, settlements[0])}
                      </div>
                      : null
                    : null
                )
              })
            }
          />
        </Col>
        <Col md={12} lg={6}>
          <Card
            title='Wartości'
            content={
              Object.entries(colParams).map((row, key2) => {
                const tooltip = row[1].tooltip
                  ? `<span class="info-tooltip">
                    <img src='${QuestionMarkIco}' alt='' style="width:12px;height:12px" />
                    <div style="left:30px;right:auto;width:270px;">
                      <p style="font-size:11px;min-width:auto;">${row[1].tooltip}</p>
                    </div>
                  </span>`
                  : ''
                return (
                  row[1].card === 4
                    ? Object.keys(showInputs).filter(el => showInputs[el] === true).includes(row[0])
                      ? <div key={key2}>
                        <label dangerouslySetInnerHTML={{ __html: row[1].pl + tooltip }} />
                        {this.getRowValues(row[0], slug, colParams, settlements[0])}
                      </div>
                      : null
                    : null
                )
              })
            }
          />
          <Card
            title='Przedpłaty'
            content={
              Object.entries(colParams).map((row, key2) => {
                const tooltip = row[1].tooltip
                  ? `<span class="info-tooltip">
                    <img src='${QuestionMarkIco}' alt='' style="width:12px;height:12px" />
                    <div style="left:30px;right:auto;width:270px;">
                      <p style="font-size:11px;min-width:auto;">${row[1].tooltip}</p>
                    </div>
                  </span>`
                  : ''
                return (
                  row[1].card === 5
                    ? Object.keys(showInputs).filter(el => showInputs[el] === true).includes(row[0])
                      ? <div key={key2}>
                        <label dangerouslySetInnerHTML={{ __html: row[1].pl + tooltip }} />
                        {this.getRowValues(row[0], slug, colParams, settlements[0])}
                      </div>
                      : null
                    : null
                )
              })
            }
          />
          <Card
            title='Rezerwy'
            content={
              Object.entries(colParams).map((row, key2) => {
                const tooltip = row[1].tooltip
                  ? `<span class="info-tooltip">
                    <img src='${QuestionMarkIco}' alt='' style="width:12px;height:12px" />
                    <div style="left:30px;right:auto;width:270px;">
                      <p style="font-size:11px;min-width:auto;">${row[1].tooltip}</p>
                    </div>
                  </span>`
                  : ''
                return (
                  row[1].card === 3
                    ? Object.keys(showInputs).filter(el => showInputs[el] === true).includes(row[0])
                      ? <div key={key2}>
                        <label dangerouslySetInnerHTML={{ __html: row[1].pl + tooltip }} />
                        {this.getRowValues(row[0], slug, colParams, settlements[0])}
                      </div>
                      : null
                    : null
                )
              })
            }
          />
        </Col>
        <Col>
          {logs.create ? <i className='small' style={{ marginLeft: 15 }}>Utworzone przez: {(mainMap.users[logs.create.user] || {}).name}, w dniu: {moment(logs.create.timestamp).local().format('YYYY.MM.DD HH:mm')}</i> : null}
        </Col>
      </div>
    )
  }

  renderSaveButton () {
    const { settlements = [], correction } = this.state
    const data = settlements[0] || {}

    return (
      <input
        type='submit'
        className={data.settleDate || data.correction || correction ? 'btn' : 'btn btn-primary'}
        value='Zapisz wersję roboczą'
        disabled={data.settled === 'tak' || data.settleDate || data.correction || correction}
        onClick={() => {
          this.dataValidation()
        }}
      />
    )
  }

  renderFixButton () {
    const { settlements = [], isFinance } = this.state
    const data = settlements[0] || {}

    return (
      isFinance
        ? <input
            type='submit'
            className='btn btn-primary'
            disabled={!(data.settled === 'tak' && data.settleDate)}
            value='Popraw'
            onClick={() => {
              data.fix = true
              this.setState({ data })
              this.dataValidation()
            }}
          />
        : null
    )
  }

  renderSettledButton () {
    const { settlements = [], readOnlyInputs } = this.state
    const data = settlements[0] || {}

    const options = [
      { value: 'nie', label: 'nie' },
      { value: 'tak', label: 'tak' }
    ]

    const initValue = {
      value: data.settled || 'nie',
      label: this.getLabel(options, data.settled, 'settled') || 'nie'
    }

    return (
      <div style={{ display: 'inline-flex', alignItems: 'center', marginLeft: 20 }}>
        <div style={{ marginRight: 5 }}>Rozliczone</div>
        <Select
          options={options}
          name='settled'
          value={initValue}
          placeholder={false}
          isDisabled={readOnlyInputs.includes('settled') || data.settleDate}
          onChange={(e) => {
            data.settled = e.value
            this.setState(() => ({ settlements: [data] }), () => this.dataValidation())
            this.checkInvalidInput(data)
          }}
        />
        <input
          type='submit'
          className={(data.settled !== 'tak' || (data.settleDate)) ? 'btn' : 'btn btn-danger'}
          value='Rozlicz'
          disabled={data.settled !== 'tak' || (data.settleDate)}
          onClick={() => {
            this.dataValidation()
          }}
        />
      </div>
    )
  }

  handleSubmit (e) {
    e.preventDefault()
    e.stopPropagation()
    const { slug, correction, today, settlements, formValid } = this.state
    this.dataValidation()

    if (formValid) {
      this.showNotification('info', 'Wysyłanie danych')
      const inputValues = {}
      let pair
      for (pair of Object.entries(settlements[0])) {
        inputValues[pair[0]] = pair[1] || ''
      }
      for (pair of Object.entries(settlements[0])) {
        if (correction) {
          inputValues.correction = correction
        }
        if (slug === 'add') {
          inputValues._id = null
          inputValues.createDate = today
        } else {
          inputValues._id = slug
        }
        if (pair[0] === 'settled' && pair[1] === 'tak') {
          inputValues.settleDate = today
          if (!inputValues.validDate) {
            inputValues.validDate = moment(inputValues.month).endOf('month').format('YYYY-MM-DD')
          }
        }
      }

      fetch(`${API_SERVER}/api/save-settlement`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputValues)
      })
        .then(res => res.json())
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', error)
        })
        .then(response => {
          if (response && response.result) {
            this.showNotification('success', 'Dane zostały zapisane', 6)
            if (slug === 'add') {
              this.setState({ slug: response.result[0].newData[0]._id })
            }
            if (response.result.length) {
              settlements[0].settleDate = response.result[0].newData[0].settleDate
              this.setState({ settlements })
            }
          } else {
            this.showNotification('error', 'Wystąpił błąd')
            console.error('Error:', response)
          }
        })
    } else {
      this.showNotification('error', 'Formularz zawiera błędy')
    }
  }

  render () {
    const { loader } = this.state
    return (
      <div className={`content SettlementsDetails ${loader ? 'noEvents' : ''}`}>
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} style={style} />
        <Loader display={loader} />
        <form className='form-inline' onSubmit={this.handleSubmit}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div className='breadcrumb'>
                      <Link to='/settlements'>Panel rozliczeń</Link>
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>»</span>
                      {window.location.href.includes('add?')
                        ? <Link to='/settlements/jobs'>Lista podzleceń</Link>
                        : <Link to='/settlements/list'>Lista rozliczeń</Link>}
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>»</span>Szczegóły rozliczenia
                    </div>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  <div
                    className='btn-group'
                    role='group'
                    aria-label='Basic example'
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <div>
                      {this.renderSaveButton()}
                      {this.renderSettledButton()}
                    </div>
                    <div>
                      {this.renderFixButton()}
                    </div>
                  </div>
                </nav>
              </Col>
            </Row>
            <Row className='newRender'>
              {this.newrenderSettlementsDetailsTable()}
            </Row>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(SettlementDetails)
